@import "../../scss/default/color_variable";
.listsection{

    h3{
        font-size: 21px;
        color: $accent;
        text-transform: uppercase;
        letter-spacing: 3px;
     
    }
    label.cs-primary_color{
        color: #BDBDBD;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 3px;
    }
    // .filter{
    //     transition: all 0.4s ease;
    // }
    // .is-sticky:after{
    //     background: linear-gradient(267.18deg, #161616 0%, #080808 100%);
    //     content: '';
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    //     width: 100%;
    //     top: 0;
    //     height: 100px;
    //     z-index: 0;
    // }
    // .is-sticky {
    //     position: fixed;
    //     top: 0;
    //     z-index: 999;
    //     animation: 0.4s ease 1s normal none 2 running slide;
    //     left: 0;
    //     right: 0;
    //     max-width: 1320px;
    //     margin: 0 auto;
    //     *{
    //         position: relative;
    //         z-index: 2;
    //     }
    //   }
    .form-control, .css-13cymwt-control, .css-1cedlwz-control{
        min-height: 48px;
        font-weight: 600;
    }
    .form-control{
        padding: 0.375rem 0.75rem 0.375rem 15px;
    }
    .css-1fdsijx-ValueContainer{
        padding: 2px 8px 2px 15px;
    }
    .css-1nmdiq5-menu{
        z-index: 99;
    }
    .css-1n6sfyn-MenuList {
        div{
            padding: 8px 15px;
            color: $darkblack;
        }
        .css-wxi4xn-option{
            color: $white;
        }
        .css-1luz86k-option{
            color: $white;
        }
              
    }
    .css-1u9des2-indicatorSeparator{
        display: none;
    }
    .cs-btn.cs-style1{
        &.primary-btn {
        margin-top: 25px;
        border: 2px solid $accent;
        background-color: $accent;
        border-radius: 5px;
        transition: all 0.6s ease-out;
        text-transform: uppercase;
        padding: 12px 26px;
        &::before {
            background-color: #D91555;
            border-radius: 0px;
        }
        &:hover {
            color: $white !important;
        }
        }
        svg{
        margin: 0 5px 0 0;
        }
    }

    .userlistcol{
    .col-sm-3{
        width: 20%;
    }
    // &.g-2{
    //     --bs-gutter-y: 0.8rem;
    // }
    // &.g-2{
    //     --bs-gutter-x: 0.8rem;
    // }
    }

    .g-2{
        --bs-gutter-y: 0.8rem;
    }
    .g-2{
         --bs-gutter-x: 0.8rem;
    }
}
@media screen and (max-width: 1200px){
.listsection{
    .userlistcol {
        .col-sm-3 {
            width: 25%;
        }
    }
} 
}
@media screen and (max-width: 950px){
    .listsection{
        .userlistcol {
            .col-sm-3 {
                width: 33.3%;
            }
        }
    } 
}
@media screen and (max-width: 590px){
    .listsection{
        .userlistcol {
            .col-sm-3 {
                width: 50%;
            }
        }
    } 
}