@import "../../scss/default/color_variable";
body{
    padding-right: 0 !important;
    &.modal-open{
            #root {
                filter: blur(5px);
            }
    }
    .modal-backdrop.show {
        opacity: 0.8;
    }
    .modal-dialog {
        max-width:700px;
       
    .modal-content{
        background-color: #181818;
        background: #181818 url("../../../public/images/custom/modal.png") no-repeat center;
        background-size: cover;
        border: 2px solid $accent;
        padding: 40px;
        p{
            margin: 0;
           color: $white;
        }
        h2{
            font-size: 28px;
            text-transform: uppercase;
            font-weight: 700;
            color: $white;
            letter-spacing: 2px;
        }
        .cs-btn.cs-style1{
            &.primary-btn {
            margin-top: 25px;
            border: 2px solid $accent;
            background-color: $accent;
            border-radius: 5px;
            transition: all 0.6s ease-out;
            text-transform: uppercase;
            padding: 12px 26px;
            &::before {
                background-color: #D91555;
                border-radius: 0px;
            }
            &:hover {
                color: $white !important;
            }
            }
        }
       
        small{
            display: block;
            margin-top: 15px;
        }
    }

    }
}
@media (max-width: 767px){
    body{
        .modal-dialog {
            max-width:500px;
            width: 90%;
            margin: 0 auto;
            
            .modal-content{
                padding: 30px 10px 20px;
                .cs-site_branding{
                    display: none;
                }
                h2{
                    font-size: 22px;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 13px;
                }
                small{
                    font-size: 10px;
                }
            }
           
        }
    }
}